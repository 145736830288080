<template>
  <div class="content gradient">
    <article class="row text-center" >
      <vue-whatsapp-widget
        phoneNumber="+524421867547"
        companyName="tulum.party"
        textReply="Typically replies within 10-15 mins (8am - 10pm)"
        companyLogo="https://imgix.cosmicjs.com/08f3cb30-e877-11ec-9177-7506dda64181-2022-06-09-23.37.07.jpg?h=50"
      />

      <section class="text-center col-lg-8 col-sm-12">
        <ResponsiveImage :image="mainImage"></ResponsiveImage>
        <h1 style="margin-top: 35px; font-weight: normal;font-family: 'Oswald', sans-serif" class="header-alt">
          TULUM
        BEACH CLUBS
        </h1>

        <p style="font-size: 1rem; margin-top: -25px">
          Day parties, Day passes & Beach Club Reservations

        </p>
        <br />

        <br />
        <p>
          <strong style="font-size: 0.9rem">
            Make Your Day Unforgettable!</strong
          >
        </p>
        <br />

        <p style="margin-top: -25px">
          Experience Tulum At Its Fullest !

        </p>

        <br />
        <img v-lazy="saxDoc+'?h=110&w=60&auto=enhance'" height="110px" width="60px" style="margin-bottom: -5px">

        <card
          class="card text-center"
          style="
          border-top: 4px solid #c8b7a8;
                background-color: rgba(15, 14, 14, 0.7) !important;
            z-index: 1;
            z-index: 1;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            color: #00f2c3 !important;
            margin-bottom: 50px;
            padding: 10px;
          "
        >
          <p style="font-size: 0.8rem;color: #ffffff !important;">
            <strong>Unleash the Magic of Tulum's Blue Beaches</strong> <br />
           Spend Your Day In The Best Way
            <br>
            <br>
          </p>


          <div >
            <img v-lazy="qrWhats" width="300px" height="300px" alt="contact us">
          </div>


          <hr>

          <div class="col-12" style="margin-top: 20px">
            <RsvpBeachClubsViaDialog></RsvpBeachClubsViaDialog>
          </div>

          <br />

          <small>
            We operate from 8AM - 11PM / Answer within 15 mins</small
          >
          <p style="font-size: 0.7rem; font-weight: bold">
            Your All-In-One Solution for a Successful Celebration!
          </p>
        </card>

        <p style="max-width: 80%" class="centered">
          Tulum's a gem, a hidden treasure,
          <router-link :to="{ path: '/blog' }">Explore</router-link> its beauty,
          beyond all measure.
        </p>


        <div class="row gradient">
          <div
            class="col-sm-12 card"
            v-for="(row, idx) in venues"
            :key="idx"
            style="
              box-shadow: #000000 10px 10px 2px;
              padding: 30px;
              flex-flow: column;
            "
          >


            <ResponsiveImage
              :image="row.metadata.main_image.imgix_url"
              :title="row.title"
              wl="600"
              hl="300"
              ws="370"
              hs="400"
            ></ResponsiveImage>
            <h5
              class="tulum-party-title"
              style="
                font-size: 1.9rem;
                margin-top: 20px;
                text-transform: uppercase;
                text-shadow: black 1px 1px 1px;
                display: inline;
              "
            >
              {{ row.title }}
            </h5>
            <p v-if="clubsConfig[row.slug]" style="margin-top: -20px">
              {{ clubsConfig[row.slug].caption }}
            </p>

            <br />

            <div
              style="
                background-color: rgba(15, 14, 14, 0.7) !important;
                margin-bottom: 10px;
                padding: 20px;
                line-height: 1.4rem;
                border-radius: 5px;
              "
              class="text-center"
            >
              <div v-html="row.metadata.description"></div>
              <Collapse>
                <CollapseItem title="Details">
                  <div v-html="row.content"></div>
                </CollapseItem>
              </Collapse>
            </div>
            <!--      DESKTOP -->
            <br />
            <RsvpBeachClubsViaDialog
              :row="row"
              :config="clubsConfig[row.slug]"
            ></RsvpBeachClubsViaDialog>
          </div>
        </div>
        <hr />
        <SmartMenu
          :links="links"
          :logo="this.mainImage"
          ctaText="DROP US A LINE"
          top-section-name="DAY CLUBS"
          :secondary-color="secondaryColor"
          :primary-color="primaryColor"
        >
          <div class="row">
            <div
              class="col-sm-12 text-center centered"
              style="max-width: 300px"
            >
              <p
                style="
                  color: #efefef !important;
                  font-size: 1.2rem;
                  font-weight: bold;
                "
              >
                Feeling Lost?
              </p>
              <hr />
              <p style="color: #efefef !important">
                We Are Your Single Contact for All Clubs & Events:
                <strong>Simplify your planning!</strong>
              </p>
              <hr />
            </div>
          </div>
        </SmartMenu>
      </section>

      <aside class="card col-4 col-lg-4 d-none d-lg-inline">
        <SmartMenu
          :links="links"
          :logo="this.mainImage"
          ctaText="DROP US A LINE"
          top-section-name="DAY CLUBS"
          :primary-color="primaryColor"
        >
          <div class="row">
            <div class="col-sm-12 text-center">
              <p style="color: #efefef !important">Feeling Lost?</p>
              <hr />
              <p style="color: #efefef !important">
                We Are Your Single Contact for All Clubs & Events:
                <strong>Simplify your planning!</strong>
              </p>
              <hr />
            </div>
          </div>
        </SmartMenu>
      </aside>
    </article>
  </div>
</template>

<script>
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';
import SmartMenu from '@/components/SmartMenu/SmartMenu';
import beachClubsConfig from '../../common/beachClubsConfig';
import ResponsiveImage from '@/components/HeadImage/ResponsiveImage';

const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
  );
const RsvpBeachClubsViaDialog = () =>
  import(
    /* webpackChunkName: "RsvpDialogs" */ '../../components/Reservations/RsvpBeachClubsViaDialog'
  );

export default {
  name: 'BeachClubs.vue',
  data: () => {
    return {
      showPalms: false,
      saxDoc: 'https://imgix.cosmicjs.com/4b85e430-e4ee-11ef-b333-e101bec29f3d-saxdoc-png.png',
      qrWhats: 'https://imgix.cosmicjs.com/c3b60240-f712-11ec-bf2b-e93971fa55b1-image.png',
      links: [
        {
          slug: 'taboo-day-events',
          metadata: {
            text: 'Taboo Tulum Day Events',
            link_to: '/article/taboo-tulum-day-events',
            caption: 'Day Live Shows and Happenings at Taboo',
            image: {
              imgix_url:
                'https://imgix.cosmicjs.com/a23dd150-61b1-11ee-bacf-0595d1e10c0d-image.png',
            },
          },
        },
        {
          slug: 'tantra-bohemian-luxury-beach-club',
          metadata: {
            text: 'Tantra Bohemian Luxury Beachclub in Tulum',
            link_to: '/article/tantra-bohemian-luxury-beachclub-in-tulum',
            caption:
              'A jewel option for a bohemian luxury beachside experience',
            image: {
              imgix_url:
                'https://imgix.cosmicjs.com/336c7ae0-5fc8-11ef-a492-5bdc7520fe60-Captura-de-pantalla-2024-08-21-081801.png',
            },
          },
        },
        {
          slug: 'vagalume-by-day',
          metadata: {
            text: 'Vagalume Tulum by day',
            caption: 'Relax by day and party all night long!',
            link_to: '/article/vagalume-by-day',
            image: {
              imgix_url:
                'https://imgix.cosmicjs.com/c8b31980-0337-11ef-bec5-7f9403a32f29-vagalume-tulum-tables-article-3.jpg',
            },
          },
        },
        {
          slug: 'bagatelle-brunch',
          metadata: {
            text: 'Jungle Brunch At Bagatelle',
            caption: 'Enjoy the special menu, live music & soak up the sun at Bagatelle\'s Jungle Brunch.',
            link_to: '/article/jungle-brunch-at-bagatelle-tulum',
            image: {
              imgix_url:
                'https://imgix.cosmicjs.com/59b06100-5382-11ef-b1ea-f56c65dfade9-article-jungle-brunch-bagatelle.jpg',
            },
          },
        },
      ],
      clubsConfig: [{}],
      primaryColor: '#e73c7e',
      secondaryColor: '#2f2a2c',
      bgPalm:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
      mainImage:
        'https://imgix.cosmicjs.com/8e0e4a10-5fc2-11ef-b5ae-a594bb4a8e67-30861412.jpg',
      birds:
        'https://imgix.cosmicjs.com/63c3dfe0-9b0f-11ee-b34c-99d1d89e42eb-image.png',
    };
  },
  metaInfo: () => ({
    title:
      'Tulum Beach Clubs · Information & Reservations. Day Parties & Day Pass.',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.tulum.party/tulum-day-beach-clubs',
      },
    ],
    meta: [
      {
        name: 'description',
        content: 'Tulum · Top Beach Clubs To Spend Your Day',
      },
      {
        name: 'keywords',
        content:
          'Tulum, Day CLub, clubs, beach clubs, Today,  2022, Papaya Playa, Vagalume, Events, Bagatelle, Taboo, Tantra',
      },
      {
        property: 'og:title',
        content: `Tulum Beach & Day Clubs. Information & Reservations.`,
      },
      { property: 'og:type', content: 'website' },
      { property: 'og:locality', content: 'Tulum' },
      { property: 'og:country-name', content: 'Mexico' },
      {
        property: 'og:description',
        content: `Top Beach Clubs | Tulum Mexico`,
      },
      {
        property: 'og:image',
        content:
          'https://imgix.cosmicjs.com/82856090-f18b-11ec-8fb8-5d396858ac9b-photo2022-06-2112-46-08.jpg?h=300&w=300&fit=clamp',
      },
    ],
  }),
  components: {
    ResponsiveImage,
    SmartMenu,
    RsvpBeachClubsViaDialog,
    CollapseItem,
    Collapse,
    VueWhatsappWidget,
  },
  computed: {
    venues: {
      get() {
        return this.$store.state.beach_clubs;
      },
    },
    faqs: {
      get() {
        return this.$store.state.faqs_category;
      },
    },
  },
  created() {
    this.$store.dispatch('getBeachClubs', { start: 0, limit: 12 });
    window.history.scrollRestoration = 'manual';
    this.clubsConfig = beachClubsConfig;
  },
  updated() {
    // setTimeout(() => (this.showPalms = true), 5000);
  },
  methods: {
    onJoinInstagramClick() {
      window.ga('send', 'event', 'instagram', 'JOIN', 'Main', 5);
      this.$rollbar.info('Instagram join');
    },
  },
};
</script>

